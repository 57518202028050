import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import _ from "./";
var exports = {};
var GetIntrinsic = _getIntrinsic;
var callBind = _;
var $indexOf = callBind(GetIntrinsic("String.prototype.indexOf"));
exports = function callBoundIntrinsic(name, allowMissing) {
  var intrinsic = GetIntrinsic(name, !!allowMissing);
  if (typeof intrinsic === "function" && $indexOf(name, ".prototype.") > -1) {
    return callBind(intrinsic);
  }
  return intrinsic;
};
export default exports;